import React from 'react';
import Sidebar from './Components/Sidebar/Sidebar';
// import TabList from './Components/Translator/TabList';
import { MyProvider } from './Context/translateContext';

import TranslatorPanel from './Components/Translator/TranslatePanel';
// import HubSpotForm from './Components/Contacto/contact';

function App() {
  return (
    <MyProvider>
      <div className="flex">
        <Sidebar />
        <div className="flex-1">
          <div className="w-full p-4">
            <div className="p-4">
              <TranslatorPanel 
                  type={"AutoPE"}
              />
            </div>
            {/* <div className="p-4" style={{backgroundColor: 'rgba(255, 190, 85, 1.0)'}}>
              <HubSpotForm />
            </div> */}
          </div>
        </div>
      </div>
    </MyProvider>
  );
}

export default App;
